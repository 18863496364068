<template>
  <v-container class="px-0 pt-0" fluid>
    <v-row>
      <v-col cols="12" v-for="device in patientDevices" :key="device._id">
        <v-card outlined>
          <v-toolbar flat>
            <v-row no-gutters align="center">
              <v-col>
                <div>{{ PRODUCT_KIND_MAP[device.kind].name }}</div>
                <div class="caption">ID: {{ device.refId }}</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn small text :color="device.live ? 'green' : 'grey'" dark>Connected</v-btn>
            </v-row>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-col cols="12" v-for="connection in patientConnections" :key="connection._id">
        <v-card outlined>
          <v-toolbar flat>
            <v-row no-gutters align="center">
              <div>{{ connection.kind }}</div>
              <v-spacer></v-spacer>
              <v-btn small text :color="connection.live ? 'green' : 'grey'" dark>Connected</v-btn>
            </v-row>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-form ref="deviceForm" v-model="valid">
          <v-card outlined>
            <v-toolbar dense flat>
              <v-toolbar-title>Add a Device</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text @click="resetForm">Reset</v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-radio-group class="ml-4" v-model="selectedDevice" :rules="rules.requireDevice">
                <div v-for="(product,productIndex) in PRODUCTS" :key="product.value">
                  <v-divider v-if="productIndex !== 0 && selectedDevice && selectedDevice.kind === product.kind"></v-divider>
                  <v-radio :value="product" v-on:click="selectedAddOption = 'assign'; deviceId = ''">
                    <template v-slot:label>
                      <v-list-item>
                        <v-list-item-avatar tile width="60" height="60">
                          <v-img contain :src="product.image" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ product.title }}</v-list-item-title>
                          <v-list-item-subtitle>{{ product.subtitle }}</v-list-item-subtitle>
                          <div class="caption warning--text" v-if="!product.availableForOrder">
                            Not available for drop-ship
                          </div>
                          <div class="caption warning--text" v-if="product.required">
                            Required: {{ product.required }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-radio>
                  <template v-if="selectedDevice && selectedDevice.kind === product.kind">
                    <v-card-text>
                      <v-row class="px-4 pt-4">
                        <v-col cols="12" class="pt-0">
                          <div class="pb-3">What would you like to do?</div>
                          <v-select
                            :items="product.availableForOrder ? addOptions : addOptionsNoShip"
                            v-model="selectedAddOption"
                            item-text="label"
                            item-value="value"
                            outlined
                            dense
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          class="pt-0"
                          v-if="selectedAddOption === 'assign'"
                        >
                          <v-text-field
                            dense
                            outlined
                            v-model="deviceId"
                            :rules="[v => !!v || 'Device ID required', selectedDevice.idValidation || 'Device ID not valid']"
                            :label="selectedDevice.idLabel"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="selectedAddOption === 'order'">
                          <v-row no-gutters class="mb-4">
                            <div class="subtitle-1">Verify Mailing Address</div>
                          </v-row>
                          <v-row>
                            <v-col cols="12" class="py-0">
                              <v-text-field
                                outlined
                                v-model="mailAddress.line1"
                                :rules="rules.required"
                                label="Street Address"
                                required
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-text-field
                                outlined
                                v-model="mailAddress.line2"
                                label="Street Address Line 2"
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <v-text-field
                                outlined
                                :rules="rules.required"
                                v-model="mailAddress.city"
                                label="City"
                                required
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <v-select
                                outlined
                                :rules="rules.requiresValidState(stateList)"
                                v-model="mailAddress.state"
                                label="State"
                                :items="stateList"
                                required
                                dense
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <v-text-field
                                outlined
                                :rules="rules.required"
                                v-model="mailAddress.zip"
                                v-mask="mask.zip"
                                label="Postal Code"
                                required
                                dense
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text v-if="error">
                      <v-alert class="mb-0" type="error">
                        <v-col class="pa-0">
                          <div class="text-uppercase">{{ error.title }}</div>
                          <div class="caption">{{ error.text }}</div>
                        </v-col>
                      </v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn :disabled="!valid" @click="saveDevice" color="primary" depressed large block >
                        {{ selectedAddOption === 'order' ? 'Submit Order' : 'Assign Device' }}
                      </v-btn>
                    </v-card-actions>
                    <v-divider v-if="productIndex+1 !== PRODUCTS.length"></v-divider>
                  </template>
                </div>
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '../core/helpers/bus'
import { DEVICES, DEVICES_KIND_MAP } from '../store/consts'

export default {
  mixins: [HelperMixin],
  computed: {
    ...mapState('patient', ['patient']),
  },
  data() {
    return {
      deviceId: null,
      addOptions: [
        { label: 'Assign device to the patient in office', value: 'assign' },
        { label: "Ship device to the patient's mailing address", value: 'order' },
      ],
      addOptionsNoShip: [
        { label: 'Assign device to the patient in office', value: 'assign' },
      ],
      error: null,
      mailAddress: {},
      mask: {
        zip: '#####',
      },
      needsDevice: false,
      patientDevices: [],
      patientConnections: [],
      PRODUCTS: DEVICES,
      PRODUCT_KIND_MAP: DEVICES_KIND_MAP,
      rules: {
        requireDevice: [v => !!v || 'Device must be selected'],
        requiresValidState: stateList => [v => !!v || 'Required', v => stateList.includes(v)],
        required: [v => !!v || 'Required'],
        email: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
      },
      saving: false,
      selectedDevice: null,
      selectedAddOption: 'assign',
      valid: false,
      verifying: false,
    }
  },
  methods: {
    ...mapActions('enrollment', ['createOrder', 'markPatientAsEnrolled']),
    ...mapActions('rpmv2', ['assignDevice', 'getPatient', 'getPatientDevices', 'getOrCreateDevice']),
    done() {
      this.verifyDevicesAndIntegrations()
      this.needsDevice = false
      this.selectedAddOption = 'assign'
      this.resetForm()
      bus.$emit('toast', { type: 'success', text: 'Completed' })
    },
    resetForm() {
      this.selectedDevice = null
      this.selectedAddOption = 'assign'
      this.error = null
      this.$refs.deviceForm.resetValidation()
    },
    async saveDevice() {
      this.saving = true
      // If assign device
      if (this.selectedAddOption === 'assign') {
        let device
        try {
          device = await this.getOrCreateDevice({
            kind: this.selectedDevice.kind,
            refId: this.deviceId,
          })
        } catch (e) {
          return (this.error = {
            title: 'Error getting device',
            text: `There was an unexpected error getting device "${this.deviceId}" (kind="${this.selectedDevice.kind}"): "${e}". If the issue occurs again, please notify support.`
          })
        }
        try {
          await this.assignDevice({
            id: device.id,
            patientId: this.patient._id,
          })
        } catch (e) {
          if (e === 'device already assigned') {
            return (this.error = {
              title: 'Device in use',
              text: `The device is currently assigned and in use by another patient.`,
            })
          }
          return (this.error = {
            title: 'Error Assigning Device',
            text: `There was an unexpected error assigning the device the patient. If the issue occurs again, please notify support.`,
          })
        }
      } else if (this.selectedAddOption === 'order') {
        try {
          // update patient address with the verified address
          let patientInfo = {
            ...this.patient,
            id: this.patient._id,
            address: {
              ...this.mailAddress,
            },
          }
          let order = await this.createOrder({
            patient: patientInfo,
            deviceName: this.selectedDevice.name,
          })
          console.log('Order:', order)
        } catch (e) {
          return (this.error = {
            title: 'Error Ordering Device',
            text: `There was an unexpected error ordering the device. If the issue occurs again, please notify support.`,
          })
        }
      } else {
        return (this.error = {
          title: 'Unrecognized Option',
          text: `The option you selected is not recognized by our system. Please notify support.`,
        })
      }
      await this.markPatientAsEnrolled(this.patient._id)
      this.done()
    },
    async verifyDevicesAndIntegrations() {
      this.verifying = true
      this.patientDevices = await this.getPatientDevices( this.$route.params.id )
      let { oauthconnections } = await this.getPatient({ patientId: this.$route.params.id })
      this.patientConnections = oauthconnections
      this.verifying = false
    },
  },
  async mounted() {
    this.needsDevice = this.$route.query.needsDevice
    await this.verifyDevicesAndIntegrations()
    this.mailAddress = { ...this.patient.address }
  },
}
</script>

<style></style>
