export const request = {
  Email: 'e',
  Name: 'n',
  Password: 'pswd',
}

export const response = {
  _id: 'Id',
  u: 'User',
  grpid: 'Communities',
}

export const DEVICES = [
  {
    kind: 'BodyTrace_BpCuff_BT105',
    title: 'Blood Pressure Cuff: BodyTrace BT105',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/bloodpressure_150x150.png'),
    name: 'BodyTrace BP Cuff BT105',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: false,
  },
  {
    kind: 'SmartMeter_iBloodPressure_SMBP802dashGS',
    title: 'Blood Pressure Cuff: Smart Meter iBloodPressure SMBP802-GS',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/smbp_150x150.png'),
    name: 'Smart Meter iBloodPressure',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: true,
  },
  {
    kind: 'Lohman_AfibAlert_V1dot5',
    title: 'ECG: Lohman AfibAlert V1.5',
    subtitle: 'Bluetooth enabled',
    image: require('@/assets/ecg_150x150.png'),
    name: 'Lohman AfibAlert',
    idLabel: 'Device ID (6-digit alphanumeric)',
    idValidation: value => /^[a-zA-Z0-9]{6}$/.test(value),
    availableForOrder: false,
  },
  {
    kind: 'SmartMeter_iGlucose_GM291',
    title: 'Glucometer: Smart Meter iGlucose GM291',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/glucose_150x150.png'),
    name: 'Smart Meter iGlucose',
    idLabel: 'Device ID Number (7-digit number)',
    idValidation: value => /^[0-9]{7}$/.test(value),
    availableForOrder: true,
  },
  {
    kind: 'SmartMeter_iPulseOx_SMPO1000dashUS',
    title: 'Pulse Oximeter: Smart Meter iPulseOx SMPO1000',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/smpo_150x150.png'),
    name: 'Smart Meter iPulseOx',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: true,
  },
  {
    kind: 'BodyTrace_Scale_BT005',
    title: 'Weight Scale: BodyTrace BT005',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/scale_150x150.png'),
    name: 'BodyTrace Weight Scale BT005',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: true,
  },
]

export const DEVICES_KIND_MAP = {
  BodyTrace_BpCuff_BT105: DEVICES[0],
  SmartMeter_iBloodPressure_SMBP802dashGS: DEVICES[1],
  Lohman_AfibAlert_V1dot5: DEVICES[2],
  SmartMeter_iGlucose_GM291: DEVICES[3],
  SmartMeter_iPulseOx_SMPO1000dashUS: DEVICES[4],
  BodyTrace_Scale_BT005: DEVICES[5],
}

function validateIMEI(value) {
  // Must be 15 digits
  if (!/^[0-9]{15}$/.test(value)) return false

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    bEven = false
  value = value.replace(/\D/g, '')

  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10)

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9

    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}